.login-container
     
    height: 100vh
    background-size: 100% 100%
    width: 70%
    background: url(https://ibb.co/YDDGqzH)
    background-size: 100% 100%
    background-repeat: no-repeat
    justify-content: center
    display: flex
    align-items: center

.mainHeading 
    font-size: 2rem
    margin-left: 0
    width: 100%
    color: #010101
    font-weight: 300
    font-weight: 500
    color: #162e70