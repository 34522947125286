input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button 
  -webkit-appearance: none
  margin: 0 

.input
    .MuiSelect-root
        padding: 18.5px 14px !important
    input
        color: black !important
        padding: 18.5px 14px !important
        height: 1px !important
    label
        transform: translate(14px, -6px) scale(0.75) !important
        padding: 0 5px
        background: white !important

#test-name-filter
    padding:20px!important

.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root
    padding:1px !important
    
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root 
    lineHeight:1em