.main-file-upload
    width: 100%
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    padding: 20px 0

    .file-uploader
        display: flex
        align-items: center